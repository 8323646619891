<template >
    <div :style="cardStyles">
      <div id="iv01" :style="cardStyles">loading...</div>
    </div>
  </template>
  
  <script>
  const {gzip, ungzip} = require('node-gzip');
      import { API_RIV04, REPORT_URL } from "@/api/constant";
      import { booking } from "@/api/booking";
      import { localize } from 'vee-validate'
      import { commonServices } from '@/api/common-services'
      //import jQuery from "jquery";
      import "@/assets/js/telerikReportViewer-17.0.23.118.min.js";
      //window.$ = jQuery;
      export default {
          name: "ReportViewer",
          data() {
              return {
                  msg: "Print invoice",
                  PaymentId: null,
                  obj: null,
                  objdeltai: null,
                  objdeltai2: null,
                  objdeltai3: null,
                  objdeltai4: null,
                  json: null,
                  isSelectBDC: false,
                  listBDC: [],
                  response: null,
                  keyWord: null,
                  pLang: null,
                  pToken: null,
                  pTimeZone: null,
                  pTimeZoneOffset: null,
                  pPaymentId: null,
                  pIndex: 0,
                  pIsGetAll: true,
                  pUrl: null,
                  fileName: null,
                  isShowDetailService: true,
                  isInvoiceOnePerson: false,
                  isPrintDirectbyPDF: false,
                  pdf: null,
                  LangOfReport: null,
                  listLanguages: null,
                  windowHeight: 0,
                  heightTable: 400,
                  cardStyles: {},
              };
          },
          async created() {
            await this.getListLanguages()
          },
          watch: {
              pUrl() {
                setTimeout(() => {
                    this.getInvoice()
                },1000)
              },
              windowHeight(value) {
                  this.heightTable = value - 100 + 'px'
                  this.$set(this.cardStyles, 'maxHeight', `${this.heightTable}`)
              }
          },
          mounted() {
            this.$nextTick(() => this.getParam())
            this.windowHeight = window.innerHeight
          },
          methods: {
            async getListLanguages() {
              await commonServices.getLanguages()
                  .then(res => {
                      if (res) {
                          this.listLanguages = res.Data.LangCategory.filter(x => x.Active == true)
                          this.LangOfReport =  this.listLanguages.find(l => l.LangId == this.$i18n.locale)
                      }
                  })
            },
              print() {
                  this.json = this.obj;
                  this.resetInvoice()
              },
              printAll() {
                  this.pIsGetAll = true
                  this.resetInvoice()
              },
              printSum() {
                  this.pIndex = 0
                  this.pIsGetAll = false
                  this.resetInvoice()
              },
              printDetail(BDCselect) {
                  for (let i = 1; i < this.response.Data.Invoice.length; i++) {
                      if (this.response.Data.Invoice[i].InfoInvoice.PayForBDC == BDCselect) {
                          this.pIndex = i
                          this.pIsGetAll = false
                      }
                  }
                  this.resetInvoice()
              },
              //1. Get thong tin payment
              async api_RIV01() {
                  this.PaymentId = this.$route.params.id * 1;
                  this.isPrintDirectbyPDF = this.$route.query.pdf;
                  const body = {
                      PaymentId: this.PaymentId,
                      Type: "ALL",
                  };
                  await booking.api_RIV01(body).then((response) => {
                      if (response.Data.PayFor.length == 1) {
                          this.isInvoiceOnePerson = true
                          this.pIsGetAll = false
                      }
                      if (response.Data.Invoice[0].ExtenalInvoiceProduct.length == 0) {
                          this.isShowDetailService = false
                      }
                      this.listBDC = response.Data.PayFor;
                      this.response = response;
                  });
              },
              //3. Get file name
              async api_RCOM01() {
                  const body = {
                      KeyGroup: "REPORT_CONFIG",
                  }
                  await commonServices.getCommon(body).then(response => {
                      this.fileName = response.Data.find(x => x.KeyCode == "IV04_REPORT_FILE_NAME").KeyValue
                  })
              },
              //4. Get url
              async api_RCF00() {
                  await commonServices.api_RCF00().then(response => {
                      this.pUrl = `${response.Data.find(x => x.SettingCode === 'SITE_API_URL').SettingValue}/api${API_RIV04}`
                  })
              },
              //2. Get config
              async getParam() {
                const gzipedData = Buffer.from(this.$route.params.id, "base64");
                await ungzip(gzipedData)
                    .then((decompressed) => {
                        this.pPaymentId = decompressed.toString()
                    });
                  const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
                  this.pLang = localStorage.getItem('systemLanguage') || '1000000'
                  this.pToken = (userGolfData) ? userGolfData.AccessToken : ''
                  this.pTimeZone = localStorage.getItem('timeZone')
                  this.pTimeZoneOffset = localStorage.getItem('timeZoneOffset')
                //   this.pPaymentId = this.$route.params.id
                  this.api_RCOM01().then(() => {
                      this.api_RCF00()
                  })
              },
              getInvoice() {
                      $("#iv01").telerik_ReportViewer({
                          serviceUrl: REPORT_URL,
                          reportSource: {
                              report: this.fileName,
                              parameters: { pIsGetAll: this.pIsGetAll, pInvoiceIndex: this.pIndex, pToken: this.pToken, pPaymentId: this.pPaymentId, pUrl: this.pUrl, pLang: this.pLang, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset, isShowDetailService: this.isShowDetailService, pUser: JSON.parse(localStorage.getItem('userData')).userName },
                          },
                          viewMode: telerikReportViewer.ViewModes.PRINT_PREVIEW,
                          scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
                          scale: 1.0,
                          sendEmail: { enabled: false },
                      });
              },
              resetInvoice() {
                  var reportViewer = $("#iv01").data("telerik_ReportViewer");
                  reportViewer.reportSource({
                      report: this.fileName,
                      parameters: { pIsGetAll: this.pIsGetAll, pInvoiceIndex: this.pIndex, pToken: this.pToken, pPaymentId: this.pPaymentId, pUrl: this.pUrl, pLang: this.LangOfReport.LangId, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset, isShowDetailService: this.isShowDetailService, pUser: JSON.parse(localStorage.getItem('userData')).userName },
                  });
              },
              ShowDetailService() {
                  this.resetInvoice()
              },
              async changeLanguage() {
                await this.api_RCOM01()
                await this.resetInvoice()
              }
          },
      };
  </script>
  
  <style lang="scss" >
  #iv01 {
    position: absolute;
    top: 50px;
    bottom: 10px;
    right: 0;
    overflow: hidden;
    clear: both;
    width: 100%;
  }
  </style>